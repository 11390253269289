
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ContentTable from "@/components/reusable/policy-information/house-insurance/ContentTable.vue";
import Generators from "@/components/reusable/policy-information/house-insurance/Generators.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "renewal-owned-building-house-insurance-component",
  components: {
    TotalPremiumWidget,
    GoBackButton,
    ContentTable,
    Generators,
  },
  data() {
    return {
      items: [] as any,
      policySummary: {} as any,
      totalPremium: 0.0 as number,
      publicId: this.$route.params.publicId as string,
      totalSumInsured: 0.0 as number,
      packageType: "",
      underwriter: {} as any,
      policyDetails: {} as any,
    };
  },
  setup() {
    const { isObjectTrulyEmpty, isEmptyArray } = ResusableFunctions();
    const router = useRouter();
    const publicId = router.currentRoute.value.params.publicId;
    const submitButton1 = ref<HTMLElement | null>(null);
    const isChecked = ref(false);

    const renewPolicy = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${variables.CART_RENEWAL_ROUTE}/${publicId}`, {})
              .then((response) => {
                variables.toastAlert(
                  "Renewal was added to cart successfully.",
                  "success"
                );

                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Renewal", [
        "Insurance Packages",
        "House Insurance Policy",
      ]);
    });

    return {
      isObjectTrulyEmpty,
      isEmptyArray,
      renewPolicy,
      submitButton1,
      isChecked,
    };
  },
  created() {
    setTimeout(() => {
      const publicId = this.publicId ?? null;
      if (publicId) {
        this.getPolicyInformation(publicId);
      }
    }, 100);

    //Set page title
    document.title =
      "Renewal: Owned Building Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicyInformation(value) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/${value}`)
          .then(({ data }) => {
            this.policyDetails = data.data;
            this.policySummary = data.data.policySummary;
            this.items = data.data.items;
            this.underwriter = data.data.underwriter;
            this.totalPremium = data.data.policySummary.premium;
            this.totalSumInsured = data.data.sumInsured;
            this.packageType = data.data.businessType;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
